@import 'erpcore/assets/scss/main';

.pagination {
    color: getColor(mid-grey);

    @include breakpoint($to: tablet) {
    }

    &__button {
        font: $font-list;
        color: getColor(mid-grey);
        padding: 0;
        position: relative;
        min-width: $spacing * 3;
        padding: 0 4px;
        background: transparent;
        border: none;
        z-index: 2;
        line-height: $spacing * 3;
        text-align: center;
        cursor: pointer;
        transition: getTransition();

        .svg {
            width: $spacing;
            fill: getColor(black);
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            opacity: 1;
            min-width: $spacing * 3;
            height: $spacing * 3;
            border-radius: $spacing * 3 / 2;
            background: getColor(light-grey);
            z-index: -1;
            transform: scale(0);
            transition: getTransition();
        }

        &--arrow {
            padding: 0;
            margin: 0 $spacing / 2;
            color: getColor(black);

            svg {
                transition: getTransition();
            }

            &:hover {
                &:after {
                    opacity: 0.5;
                }

                svg {
                    fill: var(--hover-color);
                }
            }
        }

        &--active {
            font-weight: bold;
            color: var(--primary-color);
        }

        &--arrow,
        &--active {
            &:after {
                transform: scale(1);
            }
        }

        &--disabled {
            pointer-events: none;
        }

        &:hover {
            color: var(--hover-color);
            cursor: pointer;
        }
    }
}
