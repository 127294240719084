@import 'erpcore/assets/scss/main';

.daterange-input {
    $this: &;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: 100%;
    padding: 0 48px 0 8px;
    border: 2px solid getColor(light-grey);

    .input--icon-left & {
        padding-left: 30px;
    }

    .input--disabled & {
        background-color: getColor('light-grey');
        border: 2px solid getColor('light-grey');
    }

    &__start,
    &__end {
        position: relative;
        align-items: center;
        display: flex;
        height: 36px;
        width: 95px;
        // border-top: 1px solid transparent;
        // border-bottom: 1px solid transparent;
        // transition: border-bottom-color 300ms;

        &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            width: 0;
            height: 1px;
            background-color: transparent;
            transition: background-color 200ms, width 200ms;
            pointer-events: none;
        }

        &[data-active="1"]:after {
            width: 100%;
            background-color: var(--hover-color);
            transition: background-color 400ms 50ms, width 200ms 50ms;
        }
    }

    &__start {
        &:after {
            right: 0;

        }
    }

    &__end {
        &:after {
            left: 0;
        }
    }

    &__field {
        display: block;
        width: 100%;
        height: 24px;
        padding: 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
        font: $font-filters;
        background: #fff;
        color: #000;
        cursor: pointer;
        -webkit-appearance: none;

        @include breakpoint($to: tablet) {
            font: $font-form;
        }

        .input--disabled & {
            background-color: getColor('light-grey');
        }
    }

    &__arrow {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        height: 36px;
        width: 16px;
        color: #484848;
        transition: opacity 300ms 0ms, margin-top 200ms 100ms, width 0ms 0ms;

        &-svg {
            display: block;
            fill: #484848;
            height: 16px;
            width: 16px;
        }
    }

    &__label-icon {
        padding: 4px;
        cursor: pointer;
        fill: getColor(mid-grey);

        &-svg {
            display: block;
            width: $spacing * 2;
            height: $spacing * 2;

            svg {
                width: $spacing * 2;
                height: $spacing * 2;
            }
        }
    }

    &--empty {
        #{$this}__arrow {
            opacity: 0;
            width: 0;
            margin-top: -10px;
            transition: opacity 0ms, margin-top 0ms, width 0ms;
            pointer-events: none;
        }

        #{$this}__start {
            width: 100%;
            min-width: 95px;
        }

        #{$this}__end {
            display: none;
            width: 0;
            pointer-events: none;
        }
    }

    &--icon-only {
        border: none;
        padding: 0;

        .input--icon-left & {
            padding-left: 0;
        }

        .tooltip {
            display: flex;
        }

        #{$this}__arrow {
            display: none !important;
        }

        #{$this}__start {
            display: none !important;
        }

        #{$this}__end {
            display: none !important;
        }
    }
}
