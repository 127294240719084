@import 'erpcore/assets/scss/main';

.filter {
    $this: &;
    position: relative;
    display: inline-block;

    // region Button

    &__btn {
        margin: 0;
        padding: 0;
        width: auto;
        border: none;
        display: flex;
        cursor: pointer;
        border-radius: 0;
        flex-wrap: nowrap;
        font: $font-button;
        align-items: center;
        flex-direction: row;
        align-content: center;
        -webkit-appearance: none;
        transition: getTransition();
        justify-content: flex-start;
        color: getColor(silver-grey);
        background-color: transparent;

        &-icon {
            width: 16px;
            height: 16px;
            margin-right: $spacing * 2;

            @include breakpoint(tablet) {
                margin-right: 0;
            }

            svg {
                width: 100%;
                display: block;
                transition: getTransition();
                fill: getColor(silver-grey);
            }
        }

        &-label {
            margin-top: 3px;

            @include breakpoint(tablet) {
                display: none;
            }
        }

        &:hover {
            #{$this}__btn-icon svg {
                fill: getColor(black);
            }
        }
    }

    // endregion

    // region Dropdown

    &__dropdown {
        top: 100%;
        z-index: 10;
        width: 300px;
        display: none;
        position: absolute;
        border-radius: 3px;
        left: -2 * $spacing;
        padding: $spacing * 2;
        margin-top: $spacing * 2;
        background-color: getColor(white);
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);

        @include breakpoint(small) {
            left: -3 * $spacing - 2px;
            width: calc(100vw + 2px);
        }

        &:before {
            top: -5px;
            left: 56px;
            z-index: 1;
            width: 14px;
            height: 14px;
            content: '\A';
            position: absolute;
            pointer-events: none;
            transform: rotate(45deg);
            background-color: getColor(white);
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);

            @include breakpoint(tablet) {
                left: 17px;
            }

            @include breakpoint(small) {
                left: 27px;
            }
        }

        &:after {
            left: 0;
            top: 2px;
            right: 2px;
            z-index: 2;
            content: '\A';
            position: absolute;
            pointer-events: none;
            height: $spacing * 2;
            background-color: getColor(white);
        }
    }

    &__dropdown-header {
        padding: 0;
        width: 100%;
        display: flex;
        font: $font-body;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        list-style-type: none;
        align-content: center;
        margin: 0 0 $spacing * 2 0;
        justify-content: space-between;
        color: getColor(black);
    }

    &__dropdown-header-col {
        display: block;
    }

    &__dropdown-close {
        margin: 0;
        padding: 0;
        width: 16px;
        height: 16px;
        border: none;
        display: flex;
        cursor: pointer;
        border-radius: 0;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        -webkit-appearance: none;
        background-color: transparent;

        .svg,
        svg {
            width: 8px;
            height: 8px;
            display: block;
            transition: getTransition();
            fill: getColor(silver-grey);
        }

        &:hover {
            svg {
                fill: getColor(black);
            }
        }
    }

    // endregion

    // region Modifiers

    &--opened {
        #{$this}__btn {
            color: getColor(black);

            &-icon svg {
                fill: getColor(black);
            }
        }
        #{$this}__dropdown {
            display: block;
        }
    }

    // endregion
}
