@import 'erpcore/assets/scss/main';

.impersonated-user {
    $_root: &;
    padding: $spacing $spacing * 3 $spacing * 3;

    &--is-tooltip {
        min-width: 220px;
        padding: $spacing;
    }

    &__info {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 $spacing * 2;
        color: #9f9f9f;

        &:hover {
            text-decoration: none;
            color: #000;

            #{$_root}__name {
                color: #000;
            }
        }
    }

    &__avatar {
        flex: 0 0 32px;
        height: 32px;
        margin-top: 1px;
        margin-right: 12px;
        border-radius: 50%;
        overflow: hidden;
    }

    &__image {
        position: relative;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    &__svg {
        width: 32px;
        height: 32px;
        position: relative;
        object-fit: cover;
        background: #cecece;

        svg {
            width: 32px;
            height: 28px;
            margin-top: 4px;
            fill: #fff;
        }
    }

    &__text {
        flex: 1 1 100%;
        margin: 0;
    }

    &__subtitle {
        margin: 0;
        font-size: 12px;
    }

    &__name {
        margin: 0;
        font-size: 13px;
        font-weight: 700;
        color: #6a6a6a;
        transition: getTransition();
    }

    &__button {
        width: 100%;
    }
}
