@import 'erpcore/assets/scss/main';

.sidebar-client {
    $sidebar: &;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: $spacing * 35;
    width: $spacing * 35;
    min-height: 100vh;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    position: relative;

    @include breakpoint(desktop) {
        width: $spacing * 30;
        flex: 0 0 $spacing * 30;
    }

    @include breakpoint(tablet) {
        top: 0;
        left: 0;
        right: 0;
        width: auto;
        z-index: 99;
        min-height: 0;
        flex: 0 0 auto;
        position: fixed;
    }

    &__mobile {
        &-placeholder {
            width: 36px;
            height: 36px;
        }

        &-deck {
            background-color: var(--sidebar-color);
            min-width: 40vw;
            max-width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            right: 0;
            z-index: 98;
            transition: getTransition();
            transform: translate3d(100%, 0, 0);
            padding: 0 $spacing * 3;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            & > ul {
                padding-inline-start: 0;
            }

            &--opened {
                transform: translate3d(0, 0, 0);
            }

            #{$sidebar}__deck-footer {
                display: block;
                margin-bottom: $spacing * 3;
            }

            #{$sidebar}__deck-title {
                display: block;
            }
        }
    }

    &__hamburger {
        width: 36px;
        height: 36px;
        align-self: center;
        user-select: none;
        touch-action: manipulation;
        white-space: nowrap;
        cursor: pointer;
        border: none;
        border-radius: 50%;
        overflow: hidden;
        transition: getTransition();
        background-color: transparent;
        fill: white;

        &:hover {
            background-color: rgba(255, 255, 255, 0.3);
        }

        :global(.svg) {
            width: 20px;
            height: 20px;

            svg {
                display: block;
                fill: getColor(white);
            }
        }
    }

    &__content {
        width: 100%;
        max-width: 100%;
        z-index: 99;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;

        @include breakpoint(desktop) {
            max-width: $spacing * 30;
        }

        @include breakpoint(tablet) {
            right: 0;
            bottom: auto;
            height: $spacing * 7;
            max-width: 100% !important;
        }
    }

    &__deck {
        position: fixed;
        top: 0;
        left: 0;
        max-width: $spacing * 35;
        width: $spacing * 35;
        display: flex;
        width: 100%;
        height: 100vh;
        flex-direction: column;
        background: linear-gradient(to bottom, var(--sidebar-color), #1a071e);
        box-shadow: 0 0 3px 0 rgba(102, 103, 112, 0.42);
        transition: getTransition();
        overflow-x: hidden;
        z-index: 9;
        padding: $spacing * 3;
        justify-content: space-between;

        @include breakpoint(tablet) {
            height: 100%;
            width: 100%;
            flex-direction: row;
            padding: $spacing;
            position: relative;
            max-width: 100%;
            background: var(--sidebar-color);
        }

        &-back-link {
            display: flex;
            align-items: center;

            a,
            svg {
                color: white;
                fill: white;
            }
        }

        &-image {
            width: 100%;
            height: auto;
            min-height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            overflow: hidden;
            margin-bottom: $spacing * 3;

            img {
                width: 100%;
                height: auto;

                @include breakpoint(tablet) {
                    height: 100%;
                    width: auto;
                }
            }

            &--default {
                border: 1px solid white;
                width: 100%;
                height: 150px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;

                @include breakpoint(tablet) {
                    padding: 0 $spacing;
                }

                h4 {
                    color: white;
                    margin-bottom: 0;
                }
            }

            @include breakpoint(tablet) {
                border-radius: 5px;
                height: 100%;
                margin-bottom: 0;
                min-height: unset;
            }
        }

        &-info {
            color: white;

            @include breakpoint(tablet) {
                display: none;
            }
        }

        &-title {
            margin-bottom: $spacing;
            color: white;

            @include breakpoint(tablet) {
                display: none;
            }
        }

        &-footer {
            &__copy {
                border-top: 1px solid white;
                display: flex;
                justify-content: center;
                padding-top: $spacing * 1.5;

                svg,
                & > span {
                    width: 80px;
                }
            }

            @include breakpoint(tablet) {
                display: none;
            }
        }

        &-account {
            display: flex;
            justify-content: space-between;
            padding: $spacing 0;

            &-image {
                width: 38px;
                height: 38px;
                border-radius: 50%;
                overflow: hidden;
                min-width: 38px;
                margin-right: $spacing;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid white;
            }

            &-phone {
                font-size: 11px;
                color: getColor('silver-grey');
                margin-bottom: 0;
            }

            &-email {
                font-size: 10px;
                color: getColor('light-grey');
            }

            &-name {
                flex-grow: 1;

                h4 {
                    color: white;
                    margin-bottom: $spacing / 2;
                }
            }
        }
    }
}
