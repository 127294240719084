@import 'erpcore/assets/scss/main';

.suspense-loader {
    position: absolute;
    display: block;
    text-align: center;
    left: 0;
    right: 0;
    top: 0;
    max-width: 100% !important;
    height: 3px;
    background: var(--primary-color);
    animation: suspenseAnimation .2s ease-in-out;
    transform: scaleX(1);
    transform-origin: left;
}

@keyframes suspenseAnimation {
    from { opacity: 0; transform: scaleX(0); }
    to   { opacity: 1; transform: scaleX(1); }
}
