@import 'erpcore/assets/scss/main';

.front-main {
    width: 50%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: stretch;
    align-items: center;
    justify-content: center;

    @include breakpoint($to: tablet) {
        width: 100%;
        padding: $spacing * 4;
    }

    &__content {
        width: 100%;
        max-width: $spacing * 54;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-content: stretch;
        justify-content: center;
    }

    &__title {
        font: $font-1;
        margin: 0px;
    }

    &__subtitle {
        margin: $spacing 0 0 0;
    }

    .form {
        margin-top: $spacing * 3;

        &:first-child {
            margin-top: 0px;
        }
    }

    .page-notifications {
        padding-top: $spacing * 3;
    }

    .notification {
        margin: ($spacing * 3) 0 0 0;

        &:first-child {
            margin-top: 0;
        }
    }

    .page-content {
        padding-top: $spacing * 3;
    }
}
