@import 'erpcore/assets/scss/main';

.parent {
    position: absolute;
    z-index: 2;
    top: 21px;
    transform: translateX(-50%);
    left: 50%;

    @include breakpoint($to: tablet) {
        top: 14px;
    }

    &:after {
        content: ' ';
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 11px;
        right: 6px;
        background-image: url(~erpcore/assets/svg/arrowDown.svg);
        background-size: 8px 8px;
        background-position: center;
        background-repeat: no-repeat;
        pointer-events: none;

        @include breakpoint($to: tablet) {
            top: 7px;
        }
    }

    select {
        position: relative;
        display: block;
        width: 154px;
        max-width: 154px;
        height: 38px;
        margin: 0;
        padding: 0 24px 0 13px;
        font: $font-form;
        font-size: 14px;
        border-radius: 3px;
        font-weight: normal;
        resize: none;
        color: getColor(black);
        background-color: getColor('white');
        border: 2px solid getColor('light-grey');
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;

        @include breakpoint($to: tablet) {
            max-width: 100%;
            width: 140px;
            height: 30px;
            font-size: 12px;
        }

        &::-ms-expand {
            display: none;
        }

        &:hover {
            cursor: pointer;
        }

        &:focus {
        }

        option {
            font-weight: 400;
        }
    }
}
