@import 'erpcore/assets/scss/main';

.fileuploader {
    $this: &;
    width: 100%;
    display: block;

    // region Icon

    &__icon {
        width: 20px;
        height: 20px;
        display: block;
        margin: 0 auto;

        svg,
        .svg {
            width: 100%;
            display: block;
            fill: getColor(black);
        }
    }

    // endregion

    // region Text

    &__text {
        display: block;
        font: $font-form;
        margin: 20px auto 0 auto;
        color: getColor(black);
    }

    &__label {
        padding: 0;
        display: block;
        font: $font-label;
        margin: $spacing 0 0 0;
        color: getColor(mid-grey);
    }

    // endregion

    // region Drop Area

    &__droparea {
        font: $font-2;
        margin: 0 auto;
        position: relative;
        border-radius: 3px;
        text-align: center;
        padding: $spacing * 5;
        color: getColor(mid-grey);
        background-color: getColor(light-grey);
        border: 1px dashed getColor(mid-grey);
    }

    &__droparea-inner {
        width: 100%;
        display: block;

        .button {
            margin-top: $spacing;
            background-color: getColor(white);
        }

        .react-fine-uploader-file-input {
            z-index: 2;
            cursor: pointer;
        }
    }

    &__droparea--active {
        border: 1px solid getColor(black);
    }

    // endregion

    // region Error

    &__error {
        display: block;
        flex: 0 0 100%;
        width: 100%;
        margin: ($spacing * 2) 0 0;
    }

    // endregion

    // region File list

    &__filelist {
        width: 100%;
        display: block;
        margin-top: $spacing * 2;
    }

    &__filelist-title {
        padding: 0;
        font: $font-form;
        margin: 0 0 $spacing 0;
        color: getColor(black);

        .element-loader {
            margin-right: $spacing;
        }
    }

    &__filelist-items {
        margin: 0;
        padding: 0;
        font: $font-list;
        list-style-type: none;
        color: getColor(black);
    }

    &__filelist-item {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        padding: $spacing 0;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid getColor(light-grey);

        &:last-child {
            border-bottom: none;
        }
    }

    &__filelist-item--deleting {
        opacity: 0.5;
        pointer-events: none;
    }

    &__filelist-name {
        color: getColor(dark-grey);
        padding-right: $spacing;
    }

    &__filelist-remove {
        margin: 0;
        padding: 0;
        border: none;
        cursor: pointer;
        border-radius: 0;
        -webkit-appearance: none;
        background-color: transparent;

        .svg,
        svg {
            width: 16px;
            height: 16px;
            display: block;
        }

        svg {
            transition: getTransition();
            fill: getColor(black);
        }

        &:hover {
            svg {
                fill: getColor(red);
            }
        }
    }

    // endregion
}
