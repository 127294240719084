@import 'erpcore/assets/scss/main';

.hero {
    $this: &;
    position: relative;
    width: 50%;
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: $spacing * 3;
    background-size: cover;
    background-position: center center;

    &--loader {
        background-color: white;
    }

    &--fetching-branding {
        opacity: 0;
    }

    @include breakpoint($to: tablet) {
        width: 100%;
        height: auto;
    }

    &__content {
        position: relative;
        width: 100%;
        height: auto;
        z-index: 10;
        display: flex;
        justify-content: center;

        #{$this}__logo {
            width: 256px;
            max-width: 256px;
        }
    }

    &__logo {
        display: block;
        width: 190px;
        max-width: $spacing * 41;
        height: auto;
        margin: 0 auto;

        @include breakpoint($to: tablet) {
            width: 120px;
            max-width: 100%;
        }

        svg {
            display: block;
            width: 100%;
            height: auto;
            fill: getColor(white);
        }
    }

    &__headline {
        font: $font-body;
        text-align: center;
    }

    &__footer {
        position: absolute;
        bottom: $spacing * 8;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;

        @include breakpoint($to: tablet) {
            bottom: $spacing * 4;
        }

        #{$this}__logo {
            @include breakpoint($to: tablet) {
                margin: 0px;
            }
        }
    }

    &__background {
        position: absolute;
        display: block;
        width: 70%;
        max-width: $spacing * 62;
        height: auto;
        fill: getColor(silver-light-grey);
        opacity: 0.5;
        z-index: 1;

        svg {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}