@font-face {
    font-family: 'Roboto';
    src: url(~erpcore/assets/fonts/Roboto/Roboto-Regular.eot);
    src: url(~erpcore/assets/fonts/Roboto/Roboto-Regular.eot?#iefix) format('embedded-opentype'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Regular.woff2) format('woff2'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Regular.woff) format('woff'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Regular.svg#Roboto-Regular) format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(~erpcore/assets/fonts/Roboto/Roboto-Medium.eot);
    src: url(~erpcore/assets/fonts/Roboto/Roboto-Medium.eot?#iefix) format('embedded-opentype'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Medium.woff2) format('woff2'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Medium.woff) format('woff'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Medium.ttf) format('truetype'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Medium.svg#Roboto-Medium) format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(~erpcore/assets/fonts/Roboto/Roboto-Bold.eot);
    src: url(~erpcore/assets/fonts/Roboto/Roboto-Bold.eot?#iefix) format('embedded-opentype'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Bold.woff2) format('woff2'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Bold.woff) format('woff'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Bold.ttf) format('truetype'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Bold.svg#Roboto-Bold) format('svg');
    font-weight: 700;
    font-style: normal;
}
