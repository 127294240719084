@import 'erpcore/assets/scss/main';

.time-tracking-widget-dropdown {
    $root: &;
    position: relative;
    float: right;
    margin: $spacing / 2;

    &--active {
        #{$root}__content {
            display: block;
        }
    }

    &__content {
        position: absolute;
        right: $spacing * 2;
        padding: $spacing * 2;
        padding-bottom: 0;
        width: 1000px;
        max-width: 80vw;
        display: none;
        transition: 0s 1s;
        background: getColor(white);
        box-shadow: 0 0 6px 0 rgba(49, 69, 141, 0.3);
        max-height: 50vh;
        overflow-x: hidden;
        overflow-y: auto;

        @include breakpoint(tablet) {
            transform: translateY(-100%);
            top: 0;
        }

        & > div {
            margin-top: 0 !important;
        }
    }
}
