@import 'erpcore/assets/scss/main';

.input-radio-group {
    $this: &;
    display: flex;
    flex-basis: 100%;
    flex-direction: column;

    &--inline-label {
        flex-direction: row;

        #{$this}__label {
            flex-basis: auto;
            margin-top: $spacing;
        }
        #{$this}__content {
            flex-basis: auto;
        }
    }

    &--block-label {
        #{$this}__content {
            display: block;

            & > div {
                margin-top: $spacing * 2;
                margin-bottom: $spacing * 2;

                &:first-child {
                    margin-top: $spacing;
                }

                &:last-child {
                    margin-bottom: $spacing;
                }
            }
        }
    }

    &__label {
        display: block;
        flex-basis: 100%;
        margin-bottom: $spacing;
        margin-right: $spacing * 2;
        // color: getColor(silver-grey);

        &-text {
            position: relative;
        }
    }

    &__content {
        display: flex;
        flex-basis: 100%;
        justify-content: left;
        flex-wrap: wrap;
    }

    &__helper-text {
        position: relative;
        bottom: -($spacing / 2);
        display: inline-block;
        width: 100%;
        font: $font-label;
        margin-top: 0px;
        color: getColor(mid-grey);

        &--error {
            color: getColor(tomato);
        }
    }

    &--required {
        #{$this}__label-text {
            &:after {
                content: '*';
                position: absolute;
                right: -11px;
                top: 0px;
                font: $font-form;
                color: getColor(silver-grey);
            }
        }
    }
}
