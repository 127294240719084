@import 'erpcore/assets/scss/main';

.specified-color-picker {
    display: inline-block;
    margin-right: $spacing;

    &__open-button {
        border-radius: 50%;
        width: 27px;
        height: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: unset;
        background-color: unset;
        margin-right: 0;

        span,
        svg {
            width: 13px;
            height: 13px;
            top: 1px;
            left: 1px;
            z-index: 3;
            position: relative;
        }

        :global(.button__label) {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            position: absolute;
            z-index: 1;

            span {
                min-width: 100%;
                min-height: 100%;
                top: 0;
                left: 0;
                border-radius: $spacing * 2;
                z-index: 1;
                position: absolute;
            }
        }
    }

    &__dropdown {
        background-color: white;
        padding: $spacing * 2;
        padding-bottom: $spacing;
        border-radius: 16px;
        position: absolute;
        max-width: 340px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        z-index: 1000;
        margin-top: $spacing;
        box-shadow: 0 0 3px 0 rgba(102, 103, 112, 0.42);

        &-color {
            padding: 0;
            width: 25px;
            height: 25px;
            margin: 0 5px $spacing 5px !important;
            border-radius: 4px;
            border: 0;
            background-color: unset;
            &:hover {
                span {
                    transform: scale(1.1);
                }
            }

            span {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                border-radius: 4px;
                left: 0;
            }
        }
    }
}
