@import 'erpcore/assets/scss/main';

.floating-notifications {
    position: fixed;
    display: block;
    left: $spacing*2;
    bottom: $spacing*2;
    width: 100%;
    max-width: 280px;
    z-index: 99999;

    &:empty,
    &--empty {
        pointer-events: none;
    }
}
