@import 'erpcore/assets/scss/main';

.tooltip {
    display: inline-block;

    &-content {
        height: 0px;
        line-height: 0px;
    }
}

.react-tooltip-lite {
    background: getColor(black);
    color: getColor(white);
    font: $font-body;
    border-radius: 3px;
    box-shadow: 0px 3px 6px 0 rgba(10, 9, 12, 0.19);
    margin-top: 3px;
}

.react-tooltip-lite-arrow {
    margin-top: 3px;
    border-color: getColor(black);
}

.tooltip-content--light {
    .react-tooltip-lite {
        background: #fff;
        border: 1px solid #bbbbbb;
        border-radius: 5px;
    }

    .react-tooltip-lite-arrow {
        display: none;
    }
}