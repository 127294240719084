@import 'erpcore/assets/scss/main';

.element-loader {
    display: inline-block;
    width: 34px;
    height: 8px;

    .svg {
        display: block;
        width: 100%;
        height: 100%;
    }

    svg {
        display: block;
        width: 100%;
        height: 100%;
    }

    .dot1 {
        fill: var(--primary-color);
    }

    .dot2 {
        fill: var(--primary-color-lighter);
    }

    .dot3 {
        fill: var(--primary-color-darker);
    }

    &--monochromatic {
        .dot1 {
            fill: getColor(silver-grey);
        }

        .dot2 {
            fill: getColor(silver-light-grey);
        }

        .dot3 {
            fill: getColor(light-grey);
        }
    }

    &--monochromatic {
        .dot1 {
            fill: getColor(silver-grey);
        }

        .dot2 {
            fill: getColor(silver-light-grey);
        }

        .dot3 {
            fill: getColor(light-grey);
        }
    }

    &--overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 100;

        .svg {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
        }

        svg {
            display: block;
            width: 34px;
            height: 8px;
        }
    }
}
