@import 'erpcore/assets/scss/main';

.input-checkbox-group {
    $this: &;
    display: flex;
    flex-basis: 100%;
    flex-direction: column;

    &--inline-label {
        flex-direction: row;

        #{$this}__label {
            flex-basis: auto;
            margin-top: $spacing;
        }
        #{$this}__content {
            flex-basis: auto;
        }
    }

    &__label {
        display: block;
        flex-basis: 100%;
        margin-bottom: $spacing;
        margin-right: $spacing * 2;
    }

    &__content {
        display: flex;
        flex-basis: 100%;
        justify-content: left;
        flex-wrap: wrap;

        &--rows {
            flex-direction: column;
        }
    }
}
