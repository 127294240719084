@import 'erpcore/assets/scss/main';

.search {
    $this: &;
    width: 100%;
    display: block;
    position: relative;
    border-radius: 3px;
    transition: getTransition();

    &:hover {
        box-shadow: 0 0 4px -2px getColor(silver-grey);
    }

    &__input {
        width: 100%;
        border: none;
        display: block;
        font-size: 14px;
        border-radius: 3px;
        height: $spacing * 4;
        -webkit-appearance: none;
        font-family: $font_family;
        color: getColor(dark-grey);
        padding: ($spacing) ($spacing * 3) ($spacing) ($spacing * 6);

        @include breakpoint(tablet) {
            font-size: 16px;
        }

        &::-ms-clear,
        &::-ms-reveal {
            display: none;
        }

        @include placeholder {
            color: getColor(silver-grey);
        }
    }

    &__icon {
        top: 0;
        left: $spacing * 2;
        position: absolute;
        pointer-events: none;
        transform: translateY(50%);

        svg {
            width: 16px;
            height: 16px;
            display: block;
            fill: getColor(dark-grey);
        }
    }

    &__close {
        top: 50%;
        z-index: 2;
        border: none;
        padding: 5px;
        display: none;
        cursor: pointer;
        right: $spacing;
        border-radius: 0;
        position: absolute;
        margin: -9px 0 0 0;
        -webkit-appearance: none;
        background-color: transparent;

        .svg,
        svg {
            width: 8px;
            height: 8px;
            display: block;
        }

        svg {
            fill: getColor(dark-grey);
        }
    }

    &--focused {
        #{$this}__icon svg {
            fill: var(--primary-color);
        }
    }

    &--active {
        box-shadow: 0 0 4px -2px var(--primary-color);

        #{$this}__icon svg {
            fill: var(--primary-color);
        }

        #{$this}__close {
            display: block;
        }
    }
}
