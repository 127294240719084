@import 'erpcore/assets/scss/main';

$datepicker__background-color: getColor('white') !default;
$datepicker__border-color: white !default;
$datepicker__highlighted-color: getColor('light-grey') !default;
$datepicker__muted-color: getColor('light-grey') !default;
$datepicker__text-color: getColor('dark-grey') !default;
$datepicker__header-color: getColor('dark-grey') !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: 50% !default;
$datepicker__day-margin: 2px !default;
$datepicker__font-size: 13px;
$datepicker__item-size: $spacing * 4 !default;
$datepicker__margin: $spacing !default;
$datepicker__navigation-size: $spacing !default;
$datepicker__triangle-size: $spacing * 2 !default;

.date-range-portal {
    position: fixed;
    z-index: 10000000;

    &--greedy:not(:empty) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.daterange-calendar {
    .react-datepicker__week {
        display: flex;
    }

    .react-datepicker__month-container {
        @include breakpoint(tablet) {
            padding-top: 28px;
        }
    }

    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next {
        @include breakpoint(tablet) {
            margin-top: 28px;
        }
    }

    .react-datepicker__day,
    .react-datepicker__month-text {
        flex: 0 0 36px;
        width: 36px;
        height: 36px;
        line-height: 36px;
        margin: 0;
        border: 1px solid getColor(light-grey);
        cursor: pointer;

        &:hover {
            border-radius: 0;
            background-color: var(--hover-color);
            border-color: var(--hover-color);
            color: #fff;
        }

        &--today {
            font-weight: bold;
        }

        &--highlighted {
            border-radius: $datepicker__border-radius;
            background-color: $datepicker__highlighted-color;
            color: #fff;

            &:hover {
                background-color: darken($datepicker__highlighted-color, 5%);
            }

            &-custom-1 {
                color: magenta;
            }

            &-custom-2 {
                color: green;
            }
        }

        &--selected,
        &--in-selecting-range,
        &--in-range {
            border-radius: 0;
            background-color: var(--primary-color-lighter);
            border-color: var(--primary-color-lighter);
            color: #fff;

            &:hover {
                border-radius: 0;
                background-color: var(--hover-color);
            }
        }

        // --rangeEdgeBorderRadius: 10px;

        &--selecting-range-start,
        &--range-start {
            // border-top-left-radius: var(--rangeEdgeBorderRadius);
            // border-bottom-left-radius: var(--rangeEdgeBorderRadius);
            background-color: var(--primary-color);
            border-color: var(--primary-color);
        }

        &--selecting-range-end,
        &--range-end {
            // border-top-right-radius: var(--rangeEdgeBorderRadius);
            // border-bottom-right-radius: var(--rangeEdgeBorderRadius);
            background-color: var(--primary-color);
            border-color: var(--primary-color);
        }

        &--outside-month {
            background-color: #fff;
            color: #fff;
            opacity: 0;
            border-color: transparent;
            pointer-events: none;
        }

        &--disabled,
        &--excluded {
            color: #a1a1a1;
            background: #ededed;
            cursor: not-allowed;

            &:hover {
                color: #a1a1a1;
                background: #ededed;
                border-color: #ededed;
            }

            &.react-datepicker__day--range-start,
            &.react-datepicker__day--range-end,
            &.react-datepicker__day--in-range {
                background-color: var(--primary-color-lighter);
                color: #fff;
                border-color: #ededed;
            }
        }

        //    &--keyboard-selected {
        //        border-radius: $datepicker__border-radius;
        //        background-color: var(--primary-color);
        //        color: #fff;
        //
        //        &:hover {
        //            background-color: var(--hover-color);
        //        }
        //    }

        &--in-selecting-range:not(&--in-range) {
            // background-color: var(--primary-color);
        }

        &--in-range:not(&--in-selecting-range) {
            .react-datepicker__month--selecting-range & {
                background-color: $datepicker__background-color;
                color: $datepicker__text-color;
            }
        }

        &--disabled {
            // cursor: default;
            // color: $datepicker__muted-color;

            &:hover {
                // background-color: transparent;
            }
        }
    }
}

.daterange-portal-wrapper {
    .react-datepicker-popper {
        &[data-placement^='bottom'],
        &[data-placement^='top'] {
            .react-datepicker__triangle {
                margin-left: 0;
            }
        }
    }
}

.input--datepicker-range {
    .input__icon {
        pointer-events: auto;
    }
}

.datepicker-input-icon-only {
    display: flex;
    align-items: center;

    .input {
        &__icon,
        &__label,
        &__action--clear {
            display: none !important;
        }
    }

    .react-datepicker-wrapper {
        display: flex;
    }
}

.datepicker-disable-select-same-day {
    .react-datepicker__day,
    .react-datepicker__month-text {
        &--selecting-range-start,
        &--range-start {
            pointer-events: none;
        }
    }
}
