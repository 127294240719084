@import 'erpcore/assets/scss/main';

.working-days {
  width: 100%;
  border-radius: 3px;
  border: 1px solid getColor('silver-light-grey');
  padding: $spacing * 2;

  &__fields {
    display: flex;

    @include breakpoint(desktop) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__day {
    position: relative;
    border-right: 1px solid getColor('silver-light-grey');
    padding: $spacing;
    justify-content: center;
    flex: 1 1 0;
    width: 0;
    min-width: 90px;
    max-width: 95px;

    @include breakpoint(mobile) {
      width: 50%;
      border-right: none;
      margin-bottom: $spacing;
    }

    :global {
      .button {
        width: 100%;
        margin: 0 0 $spacing 0;
        text-transform: capitalize;
      }
      .input__helper-text {
        position: absolute;
        bottom: -$spacing * 3;
        left: 0;
        z-index: 9;

        &:first-letter {
          text-transform: uppercase;
        }
      }
      .input__field {
        padding-right: 20px;
      }
      .input__action {
        min-width: 20px;
        padding: 0 10px;
      }
    }
    &:last-child {
      border-right: none;
    }
  }

  &__non-working-day {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    border: 2px solid getColor('silver-grey');
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px auto;
    fill: getColor('silver-grey');
  }

  &__children {
    text-align: right;
    width: 100%;
    padding: $spacing * 2 $spacing 0 $spacing;

    :global {
      .tooltip {
        margin-right: $spacing * 2;
      }
    }
  }
}