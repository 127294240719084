@import 'erpcore/assets/scss/main';

.status-management {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border: 2px solid getColor(light-grey);
    border-radius: 3px;
    padding: ($spacing * 3);
    background-color: getColor(light-grey);
    margin-bottom: $spacing * 4;
    color: getColor(mid-grey);

    &__header {
        display: flex;
        width: 100%;
        flex-direction: row;
        margin-bottom: $spacing * 2;

        &-right {
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        font: $font-2;
        flex: 1 1 auto;
        margin: 0;
    }

    &__content {
        display: block;
        width: 100%;
    }

    &__logs {
        display: table;
        width: 100%;
        margin-bottom: $spacing * 3;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__log {
        display: table-row;
        width: 100%;

        &:first-child {
            padding-top: 0;
        }

        &-item {
            display: table-cell;
            white-space: nowrap;
            padding: 4px ($spacing * 2);
            border-bottom: 1px solid getColor(silver-light-grey);

            @include breakpoint($to: tablet) {
                display: block;
                padding: 2px 0;
                border-bottom: none;
            }

            &--wide {
                white-space: normal;
                width: 100%;
            }

            &:first-child {
                padding-left: 0;

                @include breakpoint($to: tablet) {
                    padding-top: 4px;
                }
            }

            &:last-child {
                width: 100%;
                padding-right: 0;
                text-align: right;

                @include breakpoint($to: tablet) {
                    text-align: left;
                    padding-bottom: 4px;
                    border-bottom: 1px solid getColor(silver-light-grey);
                }
            }
        }
    }

    &__actions {
        width: 100%;
        margin-bottom: $spacing * 3;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__footer {
        width: 100%;
    }

    .status-badge {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
    }
}
