@import 'erpcore/assets/scss/main';

.multi-dashboards {
    height: 100%;
    display: flex;
    align-items: center;

    @include breakpoint(tablet) {
        margin-right: auto;
    }

    &__selected-dashboard {
        margin-bottom: 0;
        font: $font-4;
        line-height: normal;
    }

    &__dropdown {
        display: none;
        position: absolute;
        top: 42px;
        left: $spacing * 2;
        max-width: 330px;
        min-width: 220px;
        width: auto;
        background-color: white;
        box-shadow: 0 0 8px rgba(getColor(black), 0.13);
        border-radius: 3px;
        padding: 10px 0;

        @include breakpoint(tablet) {
            top: 15px;
            left: $spacing * 3;
            transform: translateY(-100%);
            max-width: calc(100% - 48px);
        }

        &[data-opened='true'] {
            display: block;
        }

        &-list {
            list-style-type: none;
            margin: 0;
            padding-left: 0;
            max-height: 150px;
            overflow: auto;
            scrollbar-width: thin;
            scrollbar-color: #e2e2e2 #f7f7f7;

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background: #e2e2e2;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-track {
                background: #f7f7f7;
                margin: 0;
            }

            &__item {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                transition: all 0.3s;
                padding-left: 10px;

                &:hover {
                    background-color: getColor(light-grey);
                    transition: all 0.3s;

                    :global(.favorite-button) {
                        visibility: visible;
                        opacity: 1;
                    }
                }

                &-name {
                    color: getColor(mid-grey);
                    margin: 0;
                    font-weight: 400;
                    font-size: 13px;

                    @include breakpoint(small) {
                        white-space: normal;
                        height: auto;
                        text-align: left;
                    }
                }

                &--active {
                    font-weight: 700;
                }
            }
        }
    }

    :global(.favorite-button) {
        visibility: hidden;
        opacity: 0;

        &:before {
            border: none !important;
        }

        @include breakpoint(small) {
            visibility: visible;
            opacity: 1;
        }
    }

    &__edit {
        margin: 0;
        height: $spacing * 2;
        padding: 0 $spacing;

        :global(.isvg) {
            width: 15px;
            height: 15px;

            svg {
                fill: #323232;
            }
        }
    }

    &__button {
        display: flex;
        fill: #323232;
        color: #323232;
        align-items: center;
        justify-content: center;

        & span:first-child {
            margin-right: 12px;
        }

        & span:last-child {
            margin-left: 12px;
            width: 10px;
            transition: all 0.15s;
        }

        &--active,
        &:hover {
            fill: var(--primary-color);

            path {
                transition: all 0.15s;
                fill: var(--primary-color);
            }
        }

        &--active span:last-child {
            transform: rotate(180deg);
        }

        &-truncate {
            text-overflow: ellipsis;
            overflow: hidden;
            width: 265px;
            white-space: nowrap;
            text-align: left;

            @include breakpoint(small) {
                width: 115px;
            }
        }
    }
}
