@import 'erpcore/assets/scss/main';

.favorite {
    margin-right: 8px !important;
    padding: 11px;

    :global(.isvg) {
        width: 16px;
        height: 17px;

        @include breakpoint(tablet) {
            margin-right: 12px;
        }

        & > svg {
            fill: getColor(mid-grey);
        }
    }

    &:before {
        border: 1px solid getColor(silver-light-grey);
    }

    &:focus:before {
        border: 1px solid getColor(silver-light-grey);
    }

    &:hover:before {
        border: 1px solid getColor(light-grey);
    }

    @include breakpoint(tablet) {
        &:before {
            border: none !important;
        }
    }

    &-listing {
        :global(.isvg) {
            width: 16px;
            height: 15px;

            & > svg {
                fill: getColor(silver-grey);
            }
        }
    }

    &__form {
        width: auto;
        margin: 0;
    }
}
