@import 'erpcore/assets/scss/main';

.bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: getColor(light-grey);
    padding: $spacing;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .time-log-widget-dropdown {
        margin: 0;

        &__content {
            transition: unset;

            @include breakpoint(tablet) {
                top: 0;
                transform: translate3d(0%, -102%, 0);
                max-height: 78vh;
                overflow-x: hidden;
                overflow-y: auto;
            }

            @include breakpoint(small) {
                max-width: 80vw;
                right: 50%;
                margin: 0 auto;
                transform: translate3d(5%, -102%, 0);
            }
        }
    }

    .button:last-of-type {
        margin-right: 0;
    }
}
