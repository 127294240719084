@import 'erpcore/assets/scss/main';

.props-widget {
    float: right;
    height: 100%;
    display: flex;
    align-items: center;

    &__button {
        padding: 0 $spacing;

        .svg,
        .isvg {
            width: 25px;
            height: 25px;
        }
    }

    &__icons {
        label {
            padding-left: $spacing;

            &:before,
            &:after {
                visibility: hidden;
                opacity: 0;
            }

            img {
                width: 60px;
                opacity: 0.5;
                transition: getTransition();
            }

            &:hover {
                img {
                    opacity: 1;
                }
            }
        }

        input:checked ~ label img {
            opacity: 1;
        }
    }
}
