@import 'erpcore/assets/scss/main';

.profile-settings {
    &__list {
        display: flex;

        @include breakpoint(tablet) {
            flex-direction: column;
            margin-top: $spacing * 12;
        }

        &-item {
            margin-right: $spacing * 2;
            list-style-type: none;
            display: flex;
            align-items: center;

            @include breakpoint(tablet) {
                margin-bottom: $spacing;
                padding-bottom: $spacing;

                &:last-child {
                    border-top: 1px solid getColor('light-grey');
                    margin-bottom: 0;
                    padding-top: $spacing * 2;
                }
            }

            &-user {
                border: 1px solid getColor('mid-grey');
                border-radius: 50%;
                width: 24px;
                height: 24px;
                margin-right: $spacing;
                display: flex;
                align-items: center;
                overflow: hidden;
                justify-content: center;

                @include breakpoint(tablet) {
                    width: 30px;
                    height: 30px;
                    margin-right: $spacing * 2;
                }
            }

            a {
                display: flex;
                align-items: center;
                color: getColor('mid-grey');
                font: $font-4;
                font-weight: 500;

                @include breakpoint(tablet) {
                    color: getColor(white);
                }

                & > span {
                    margin-right: $spacing;

                    @include breakpoint(tablet) {
                        margin-right: $spacing * 2;
                    }
                }
            }

            &:last-child {
                margin-right: 0;
            }

            :global(.svg) {
                @include breakpoint(tablet) {
                    width: 30px;
                    height: 30px;
                    fill: white;
                }
            }
        }
    }
}
