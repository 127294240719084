@import 'erpcore/assets/scss/main';

.location-map {
    $this: &;
    flex: 1;
    display: block;
    width: 100%;
    position: relative;
    margin: 0 $spacing * 2 0 0;

    &--disabled {
        pointer-events: none;
        opacity: 0.8;
    }

    .gm-style > div {
        cursor: pointer !important;
    }

    &:last-child {
        margin-right: 0px;
    }

    &__loading {
        height: 100%;
    }

    &__container {
        height: $spacing * 20;
        width: 100%;
        overflow: hidden;
    }

    &__map {
        height: 100%;
    }
}
