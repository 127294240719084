@import 'erpcore/assets/scss/main';

.filtertags {
    padding: 0;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    list-style-type: none;
    margin: 0 0 $spacing * 2 0;
    justify-content: flex-start;

    &__item {
        width: auto;
        display: flex;
        flex-wrap: nowrap;
        font: $font-label;
        border-radius: 3px;
        flex-direction: row;
        align-items: center;
        align-content: center;
        margin-right: $spacing;
        justify-content: flex-start;
        color: getColor(mid-grey);
        padding: 2px $spacing 2px $spacing * 2;
        background-color: getColor(light-grey);
    }

    &__label {
        &:first-letter {
            text-transform: uppercase;
        }
    }

    &__clear {
        margin-left: $spacing;
    }

    &__btn {
        padding: 2px;
        border: none;
        cursor: pointer;
        border-radius: 0;
        margin: 0 0 0 $spacing;
        -webkit-appearance: none;
        background-color: transparent;
        color: getColor(mid-grey);

        .svg,
        svg {
            width: 8px;
            height: 8px;
            display: block;
            fill: getColor(mid-grey);
        }
    }

    &__btn-clear {
        margin: 0;
        padding: 0;
        border: none;
        cursor: pointer;
        border-radius: 0;
        font: $font-label;
        text-decoration: none;
        -webkit-appearance: none;
        color: getColor(bordo);
        background-color: transparent;

        &:hover {
            text-decoration: underline;
        }
    }
}
