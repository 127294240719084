@import 'erpcore/assets/scss/main';

.sidebar {
    $sidebar: &;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: $spacing * 9;
    width: $spacing * 9;
    min-height: calc(100vh - 50px);
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    min-height: calc(100vh - 50px);

    @include breakpoint(desktop) {
        width: $spacing * 9;
        flex: 0 0 $spacing * 9;
    }

    @include breakpoint(tablet) {
        top: 0;
        left: 0;
        right: 0;
        width: auto;
        z-index: 999;
        min-height: 0;
        flex: 0 0 auto;
        position: fixed;
    }

    &__content {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        max-width: $spacing * 9;
        z-index: 999;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;

        @include breakpoint(desktop) {
            max-width: $spacing * 9;
        }

        @include breakpoint(tablet) {
            right: 0;
            bottom: auto;
            height: $spacing * 7;
            max-width: 100% !important;
        }
    }

    // region bolt

    &__bolt {
        z-index: 10;
        position: relative;
        display: flex;
        width: $spacing * 9;
        flex-direction: column;
        flex-wrap: nowrap;
        color: getColor(white);
        fill: getColor(white);
        padding: ($spacing * 4) ($spacing * 2);
        align-items: center;
        align-content: center;
        justify-content: space-between;
        background: var(--sidebar-color);

        @include breakpoint(tablet) {
            width: 100%;
            height: 100%;
            padding: 0;
        }

        &-top,
        &-bottom {
            margin: 0;
            padding: 0;
            width: 100%;
            height: auto;
            display: block;
            text-align: center;
            list-style-type: none;

            &-item {
                margin-top: $spacing * 1.5;

                .tooltip {
                    display: block;
                    margin: 0 auto;
                }
            }
        }

        &-top {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            align-content: center;
            justify-content: center;
            margin-bottom: $spacing * 2;
            flex-direction: column-reverse;

            @include breakpoint(tablet) {
                height: 100%;
                margin-bottom: 0;
                flex-direction: row;
                padding-left: $spacing * 2;
                padding-right: $spacing * 2;
                justify-content: space-between;
            }
        }

        &-top-logo {
            margin-bottom: $spacing * 4;

            @include breakpoint(tablet) {
                margin-bottom: 0;
            }
        }

        &-top-profile {
            display: none;

            @include breakpoint(tablet) {
                display: block;
            }
        }

        &-bottom {
            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

    &__logo {
        width: 100%;
        max-width: $spacing * 5;
        height: auto;
        display: block;
        margin: 0 auto;

        .svg {
            display: block;
            width: 100%;
            height: auto;
        }

        svg {
            display: block;
            fill: getColor(white);
            transition: getTransition();
        }

        &:hover {
            svg {
                filter: drop-shadow(1.1px 1.7px 1px rgba(92, 9, 43, 1));
            }
        }
    }

    &__button {
        width: 36px;
        height: 36px;
        display: block;
        user-select: none;
        touch-action: manipulation;
        white-space: nowrap;
        cursor: pointer;
        padding: $spacing;
        margin: 0 auto $spacing auto;
        border: none;
        border-radius: 50%;
        background-color: transparent;
        overflow: hidden;
        transition: getTransition();

        &:last-child {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0px;
        }

        .svg {
            display: block;
            width: 20px;
            height: 20px;

            svg {
                display: block;
                fill: getColor(white);
            }
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.15);
        }
    }

    &__profile {
        width: $spacing * 4;
        height: $spacing * 4;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        touch-action: manipulation;
        white-space: nowrap;
        cursor: pointer;
        padding: 0;
        margin: 0 auto 0 auto;
        border: none;
        border-radius: 50%;
        background-color: getColor(white);
        transition: getTransition();
        overflow: hidden;
        border: 1px solid #fff;

        &:hover {
            box-shadow: 0.5px 0.8px 0.8px 0.2px rgba(85, 99, 234, 0.41);
        }

        img {
            display: block;
            overflow: hidden;
            width: $spacing * 4;
            height: $spacing * 4;
            object-fit: cover;
        }

        .svg {
            display: block;
            width: 100%;
            height: auto;
            fill: getColor(silver-grey);
            border-radius: 50%;
            overflow: hidden;
        }

        &--transparent {
            background-color: transparent;
            border: 2px solid #fff;

            svg {
                height: 26px;
                margin-top: 6px;
                fill: #fff;
            }
        }
    }

    // endregion

    // region deck

    &__deck {
        position: relative;
        display: none;
        width: $spacing * 32;
        height: calc(100vh - 55px);
        flex-direction: column;
        padding: ($spacing * 3) 0 0 0;
        background-color: getColor(white);
        box-shadow: 0 0 3px 0 rgba(102, 103, 112, 0.42);
        transition: getTransition();
        overflow-x: hidden;
        z-index: 9;

        @include breakpoint(tablet) {
            top: 0;
            left: 0;
            bottom: 0;
            position: fixed;
            margin-top: 55px;
        }
    }

    &__mobile-overlay {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        z-index: 10;
        opacity: 0.5;
        border: none;
        width: 100vw;
        display: none;
        position: fixed;
        border-radius: 0;
        pointer-events: none;
        background-color: black;
        -webkit-appearance: none;
        max-width: 100% !important;
    }

    &__mobile-profile {
        top: 0;
        right: 0;
        z-index: 9;
        display: none;
        position: fixed;
        width: $spacing * 33;
        background-color: white;
        margin-top: $spacing * 7;
        padding-top: ($spacing * 3);
        box-shadow: 0 0 3px 0 rgba(102, 103, 112, 0.42);
    }

    &__profile-list {
        margin: 0;
        font: $font-body;
        list-style-type: none;
        padding: 0 ($spacing * 2) ($spacing * 3);

        &-item {
            &-link {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-shrink: 0;
                width: 100%;
                padding: $spacing (
                    $spacing * 2
                );
            text-decoration: none;
            color: getColor(black);

            &:hover {
                text-decoration: none;
            }
        }
    }
}

&--show-deck {
    flex-basis: $spacing * 41;
    width: $spacing * 41;

    @include breakpoint(tablet) {
        width: auto;
        flex: 0 0 auto;
    }

    #{$sidebar}__content {
        max-width: $spacing * 41;

        @include breakpoint(tablet) {
            max-width: 100%;
        }
    }

    #{$sidebar}__mobile-overlay {
        @include breakpoint(tablet) {
            display: block;
            pointer-events: all;
        }
    }

    #{$sidebar}__deck {
        display: flex;
    }

    #{$sidebar}__button {
        &--hamburger {
            background-color: rgba(255, 255, 255, 0.2);

            &:hover {
                background-color: rgba(255, 255, 255, 0.35);
            }
        }
    }
}

&--profile-opened {
    #{$sidebar}__mobile-overlay {
        @include breakpoint(tablet) {
            display: block;
            pointer-events: all;
        }
    }

    #{$sidebar}__mobile-profile {
        @include breakpoint(tablet) {
            display: block;
        }
    }
}
}