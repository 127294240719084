.u-hide-tablet {
    @include breakpoint(tablet) {
        display: none !important;
    }
}

.u-show-tablet {
    display: none;

    @include breakpoint(tablet) {
        display: block;
    }
}

.u-show-tablet-inline {
    display: none;

    @include breakpoint(tablet) {
        display: inline;
    }
}
