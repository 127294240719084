@import 'erpcore/assets/scss/main';

.PhoneInput.input__field {
    padding-left: $spacing + 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
}

//.react-phone-number-input__row {
//    /* This is done to stretch the contents of this component */
//    display: flex;
//    align-items: center;
//    margin-top: 4px;
//}

//.react-phone-number-input__phone {
//    /* The phone number input stretches to fill all empty space */
//    flex: 1;
//
//    /* The phone number input should shrink
//	   to make room for the extension input */
//    min-width: 0;
//}

.PhoneInputCountryIcon {
    /* The flag icon size is 4x3 hence the exact `width` and `height` values */
    width: 1.24em;
    height: 0.93em;
    max-width: 50px;

    /* Makes sure `width`x`height` is exactly `4x3` and `border` width is not included in it */
    box-sizing: content-box;
    margin-top: -2px;
}

.PhoneInputInternationalIconGlobe {
    /* The international icon size is square */
    /* and also has no border hence the `2 * 1px` `width` and `height` compensation */
    width: calc(0.93em + 2px);
    height: calc(0.93em + 2px);

    /* The international icon size is square hence the exact `padding` value */
    /* for precise alignment with `4x3` sized country flags. */
    padding-left: 0.155em;
    padding-right: 0.155em;
    margin-top: 0;
    border: none;
}

//.react-phone-number-input__error {
//    margin-left: calc(1.24em + 2px + 0.3em + 0.35em + 0.5em);
//    margin-top: calc(0.3rem);
//    color: #d30f00;
//}

.PhoneInputCountryIconImg {
    max-width: 100%;
    max-height: 100%;
}

///* Removes `<input type="number"/>` up/down arrows in Webkit browsers. */
//.react-phone-number-input__ext-input::-webkit-inner-spin-button,
//.react-phone-number-input__ext-input::-webkit-outer-spin-button {
//    margin: 0 !important;
//    -webkit-appearance: none !important;
//    -moz-appearance: textfield !important;
//}
//
//.react-phone-number-input__ext-input {
//    width: 3em;
//}
//
//.react-phone-number-input__ext {
//    white-space: nowrap;
//}
//
//.react-phone-number-input__ext,
//.react-phone-number-input__ext-input {
//    margin-left: 0.5em;
//}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: 0.5em;
}

.PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
    overflow: hidden;
    display: none;
}

.PhoneInputCountrySelectArrow {
    display: none;
    content: '';
    width: 0;
    height: 0;
    margin-bottom: 0.1em;
    margin-top: 0.3em;
    margin-left: 0.3em;
    border-width: 0.35em 0.2em 0 0.2em;
    border-style: solid;
    border-left-color: transparent;
    border-right-color: transparent;
    color: #b8bdc4;
    opacity: 0.7;
    transition: color 0.1s;
    transform: none;
}

/* Something from stackoverflow. */
//.react-phone-number-input__country-select-divider {
//    font-size: 1px;
//    background: black;
//}
//
//.react-phone-number-input__country-select:focus + .react-phone-number-input__country-select-arrow,
//.react-phone-number-input__country.rrui__select--focus .rrui__select__arrow {
//    color: getColor(dark-grey);
//}

/* Styling phone number input */

.PhoneInputInput {
    height: calc(0.3rem * 6);
    outline: none;
    border-radius: 0;
    padding: 0;
    appearance: none;
    border: none;
    transition: border 0.1s;
    font-size: inherit;
    /* The phone number input stretches to fill all empty space */
    flex: 1;

    /* The phone number input should shrink
	   to make room for the extension input */
    min-width: 0;
}

.PhoneInputInput:disabled {
    cursor: default;
}

.PhoneInputInput--invalid,
.PhoneInputInput--invalid:focus {
    border-color: #eb2010;
}

/* Overrides Chrome autofill yellow background color */
.PhoneInputInput:-webkit-autofill {
    box-shadow: 0 0 0 1000px white inset;
}

/* A small fix for `react-responsive-ui` */
//.react-phone-number-input__country .rrui__select__button {
//    border-bottom: none;
//}
