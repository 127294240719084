@import 'erpcore/assets/scss/main';

.page {
    display: flex;
    width: 100%;
    min-height: 100vh;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: nowrap;

    @include breakpoint(tablet) {
        display: block;
    }

    &__left {
        flex: 0 0 20%;
        padding: $spacing * 3;
        background-size: cover;
        background-position: center center;
    }

    &__right {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex: 1 1 auto;
        padding: $spacing * 6 $spacing * 3;

        @include breakpoint(tablet) {
            padding: $spacing * 3;
        }
    }
}

.logo {
    display: block;
    width: 190px;
    max-width: $spacing * 41;
    height: auto;
    margin: 0 auto;

    @include breakpoint($to: tablet) {
        width: 120px;
        max-width: 100%;
    }

    svg {
        display: block;
        width: 100%;
        height: auto;
        fill: getColor(white);
    }
}

.inner {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: left;
    max-width: 670px;
    font: $font-form;
    color: getColor(mid-grey);
}

.name {
    padding: 0;
    font: $font-1;
    display: block;
    color: getColor(black);
    margin: 0 0 $spacing * 3 0;
}

.note {
    display: block;
    margin: 0 0 $spacing * 3 0;

    p {
        display: block;
    }

    &__content {
        font-style: italic;

        a {
            color: getColor(red);
        }
    }

    &__title {
        display: block;
        margin-bottom: $spacing;
    }
}

.list {
    padding: 0;
    list-style-type: none;
    margin: 0 0 $spacing * 3 0;

    a {
        color: getColor(red);
    }

    li {
        list-style-type: none;
    }
}

.form {
    display: block;
}
