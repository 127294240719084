@import 'erpcore/assets/scss/main';

@include breakpoint($from: tablet) {
    body {
        &[has-top-bar='true'] {
            padding-top: 50px;

            & .sidebar__content {
                top: 50px;
            }
        }
    }

    .topbar {
        $topbar: &;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        height: 50px;
        width: 100%;
        background: getColor(white);
        z-index: 9999;
        box-shadow: 0 0 6px 0 rgba(49, 69, 141, 0.3);

        & > *:first-of-type {
            margin-right: $spacing * 3;
        }
    }
}

@include breakpoint($to: tablet) {
    .topbar {
        display: none;
    }
}
