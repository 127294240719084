@import 'erpcore/assets/scss/main';

.time-tracking {
    width: 100%;
    height: 100%;
    $root: &;

    &__search-icon {
        position: absolute;
        z-index: 3;
        right: $spacing * 4 - 2px;
        top: $spacing * 3 + 3px;
        background: #fff;
        pointer-events: none;
    }

    &__create-widget {
        max-height: unset !important;

        :global(.widget__content) {
            overflow: hidden;
        }
    }

    &--for-widget {
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: getColor(silver-grey) getColor(light-grey);
        padding-right: $spacing;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: getColor(light-grey);
        }

        &::-webkit-scrollbar-thumb {
            background: getColor(silver-grey);
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: getColor(silver-grey);
        }

        @include breakpoint(tablet) {
            height: 400px;
        }
    }

    &__widget {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    &__statistics {
        display: flex;
        border-bottom: 1px solid getColor('silver-grey');
        padding-bottom: $spacing * 2;
        margin-bottom: $spacing * 2;
        position: relative;
        width: calc(100% - #{$spacing * 6});
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        z-index: 10;
        transform: translateY(-8px);
        $statistics: &;

        &--mobile {
            flex-wrap: wrap;
            padding-bottom: 0;

            #{$statistics}-column {
                flex: unset;
                flex-basis: 50%;
                margin-bottom: $spacing * 2;
                padding: 0 $spacing;
            }
        }

        @include breakpoint(mobile) {
            flex-wrap: wrap;
            padding-bottom: 0;
        }

        @include breakpoint(small) {
            width: calc(100% - #{$spacing * 2});
        }

        &-column {
            flex: 1;
            display: flex;
            padding: 0 $spacing * 3;

            @include breakpoint(mobile) {
                flex: unset;
                flex-basis: 50%;
                margin-bottom: $spacing * 2;
                padding: 0 $spacing;
            }

            :global(.isvg) {
                height: 35px;
                width: 35px;

                @include breakpoint(mobile) {
                    width: 25px;
                    height: 25px;
                }

                @include breakpoint(small) {
                    width: 20px;
                    height: 20px;
                }
            }

            &-info {
                margin-left: $spacing * 2;

                @include breakpoint(small) {
                    margin-left: $spacing;
                }

                h5 {
                    margin-bottom: $spacing / 2;
                    font-weight: 300;
                    color: getColor('mid-grey');
                }

                h3 {
                    margin-bottom: 0;

                    @include breakpoint(small) {
                        font: $font-4;
                    }
                }
            }
        }
    }

    &--tablet {
        #{$root}__draft-list__single {
            &__actions {
                &-billable {
                    &-button {
                        padding: 0;
                    }
                }
            }
        }
    }

    &--tablet-small {
        #{$root}__draft-list__single {
            &-info {
                &__bottom {
                    &-remaining {
                        float: left;
                    }
                }
            }
        }
    }

    &--mobile {
        #{$root}__submitted-options {
            max-width: 0 !important;
            flex-basis: 0 !important;
        }

        #{$root}__draft-warning {
            width: calc(100% - #{$spacing * 6});
            margin-left: $spacing;
            margin-right: auto;
        }

        #{$root}__draft-list__single {
            display: flex;
            flex-direction: column;

            &-info {
                flex-basis: 100%;
                max-width: 100%;

                &__top {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: $spacing * 2;

                    h4 {
                        width: 100%;
                        align-self: flex-start;
                        margin-bottom: $spacing * 2;
                        padding-right: $spacing * 3;
                    }

                    &-work-type {
                        margin-left: 0;
                    }
                }

                &__bottom {
                    margin-bottom: $spacing * 2;
                    display: flex;

                    & > div {
                        clear: left;
                        float: unset;
                        flex: 1;
                    }

                    &-remaining {
                        justify-content: flex-end;
                        margin-right: 0;
                    }

                    & button {
                        span {
                            font: $font-body;
                        }

                        :global(.isvg) {
                            width: 17px;
                            min-width: 17px;
                        }
                    }
                }
            }

            &__actions {
                justify-content: space-between;

                flex-basis: 100%;
                max-width: 100%;

                &-time {
                    border-left: unset;
                    flex-grow: 1;
                    padding: 0;
                    display: flex;
                }

                &-timer {
                    flex-grow: 1;
                    justify-content: center;

                    :global(.input--active) {
                        flex: 0 0;
                    }
                }

                &-billable {
                    flex-basis: 25%;
                    max-width: 25%;

                    display: flex;
                    justify-content: center;
                    margin-right: 0;
                }

                &-date {
                    border-left: 1px solid getColor('silver-grey');
                    flex-basis: 40%;
                    max-width: 40%;
                    border-right: 1px solid getColor('silver-grey');
                    display: flex;
                    justify-content: center;
                }

                &-options {
                    width: unset;
                    padding: 0;
                    flex-basis: 30%;
                    max-width: 30%;
                    justify-content: center;

                    button {
                        margin-right: 0;
                    }

                    :global(.button--dropdown) {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                    }
                }
            }
        }
    }

    &--small {
        #{$root}__create {
            &-form {
                flex-direction: column;
                padding-right: 0;
            }

            &-new {
                flex-direction: column;
                flex-basis: 100%;
                max-width: 100%;
            }

            &-project,
            &-work-type {
                max-width: 100%;
            }

            &-submit {
                margin-top: $spacing;
                margin-bottom: $spacing;
                height: 33px;
                padding: $spacing $spacing * 4;
                width: 100%;
            }
        }

        #{$root}__draft {
            &-form {
                &-timer {
                    display: flex;
                    align-items: center;
                }
            }

            &-list__single {
                padding: $spacing * 3 $spacing;
            }

            &-top {
                flex-direction: column;
            }

            &-options {
                margin-top: $spacing;
                justify-content: space-between;

                &-submit {
                    border-left: 0;
                    padding: 0;
                    width: unset;
                    bottom: $spacing * 1.5;
                    position: relative;
                }

                &-total {
                    padding: 0;

                    h5 {
                        color: rgba(getColor('mid-grey'), 0.5);
                        font: $font-4;
                        font-weight: 300;

                        span {
                            font-weight: 800;
                        }
                    }
                }
            }
        }

        #{$root}__dropdown {
            width: 240px;
        }

        #{$root}__draft-list__single {
            &-info {
                &__bottom {
                    flex-wrap: wrap;

                    &-description {
                        max-width: 45%;

                        #{$root}__dropdown {
                            right: 4%;
                        }
                    }

                    &-task {
                        max-width: 45%;
                    }

                    &-remaining {
                        flex-basis: 100% !important;
                        width: 100%;
                        justify-content: flex-start !important;
                        margin-top: $spacing;
                    }
                }
            }

            &__actions {
                &-date {
                    #{$root}__dropdown {
                        right: 3%;
                        padding: $spacing;
                        width: 270px !important;

                        :global(.react-datepicker__month-container) {
                            margin: 0;
                        }

                        :global(.react-datepicker__navigation--previous),
                        :global(.react-datepicker__navigation--next) {
                            top: -5px;
                        }

                        :global(.datepicker-presets) {
                            top: -14px;
                        }
                    }
                }
            }
        }

        #{$root}__draft-list__single__actions {
            flex-direction: column;

            &-time {
                width: 100%;
                flex-grow: unset;
                flex-basis: 100%;
            }

            &-billable {
                flex-basis: 10%;
                max-width: 10%;
            }

            &-date {
                flex-basis: 40%;
                max-width: 40%;
                border-left: unset;
            }

            &-timer {
                flex-grow: 1;

                :global(.input) {
                    margin-bottom: 0;
                    flex: unset;
                    width: fit-content;
                }

                & button {
                    padding-left: $spacing * 1.5;
                    padding-right: $spacing 1.5;
                    font: $font-3;

                    &:first-child {
                        padding-right: 0;
                    }
                }
            }

            &-options {
                width: 100%;
                border-left: unset;
            }
        }
    }

    #{$root}__multiday {
        @media screen and (max-width: 450px) {
            #{$root}__draft-list__single__actions {
                &-time {
                    flex-direction: column;
                    align-items: flex-start;
                }

                &-date {
                    flex-basis: 100%;
                    max-width: 100%;
                    border-right: none;
                }

                &-timer {
                    & button:first-child {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    :global(.react-select__indicators) {
        background-color: unset !important;
    }

    &__submitted {
        border-left: unset !important;
        width: unset !important;
        padding-right: unset !important;

        :global(.button__icon) {
            width: 7px;
        }

        &--load-more {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: $spacing * 3 0;
        }
    }

    &__create {
        &-remaining {
            margin-left: $spacing;
            display: flex;
            align-items: center;
            font: $font-list;
            color: getColor('silver-grey');
            font-weight: 100;
            width: fit-content;

            :global(.svg) {
                margin-right: $spacing / 1.5;
                fill: getColor('silver-grey');

                svg {
                    fill: getColor('silver-grey');
                }
            }
        }

        &-form {
            display: flex;
            justify-content: space-between;
            margin-bottom: $spacing * 2;
            width: 100%;
            padding-right: $spacing * 2.5;

            @media (max-width: 700px) {
                flex-direction: column;
            }
        }

        &-button {
            :global(.isvg) {
                width: 13px;
                fill: getColor('silver-grey') !important;
                margin-right: $spacing * 2;
                display: inline-block;

                svg {
                    fill: getColor('silver-grey') !important;
                }
            }
        }

        &-new {
            display: flex;
            flex-basis: calc(100% - 70px);
            max-width: calc(100% - 70px);

            :global(.react-select__control) {
                border: unset;
            }

            & > div {
                &:last-child {
                    margin-right: 0;
                }
            }

            @media (max-width: 590px) {
                flex-basis: 100%;
                max-width: 100%;
            }
        }

        &-selected {
            :global(.react-select) {
                position: relative;
            }

            :global(.react-select__single-value) {
                font-size: 13px;
                font-weight: 500;
                color: getColor('mid-grey');
                top: 4px;
                position: relative;
            }

            :global(.react-select__indicators) {
                top: 4px;
            }
        }

        &-project {
            width: fit-content;
            min-width: 180px;
            max-width: 30%;
        }

        &-work-type {
            width: fit-content;
            min-width: 120px;
            max-width: 30%;
        }

        &-submit {
            @media (max-width: 700px) {
                margin-top: $spacing;
                height: 33px;
                padding: $spacing $spacing * 4;
            }
        }
    }

    &__dropdown {
        z-index: 101;
        position: absolute;
        box-shadow: 0 0 3px 0 rgba(102, 103, 112, 0.42);
        padding: $spacing * 2;
        width: 600px;
        background-color: white;
        display: none;

        :global(.react-select__menu) {
            position: relative;
            top: 0;
        }

        :global(.react-select__control) {
            border: 2px solid #f2f2f2;
        }

        &[data-opened='true'] {
            display: block;
        }
    }

    &__submitted {
        width: 100%;

        &-top {
            padding: $spacing * 1.5 $spacing * 3;
            background-color: getColor('light-grey');
            display: flex;
            justify-content: space-between;

            h5 {
                margin-bottom: 0;
                color: getColor('mid-grey');
            }
        }
    }

    &__draft {
        width: 100%;
        margin-bottom: $spacing;
        $draftForm: &;

        &-warning {
            border: 1px solid getColor(tomato);
            background-color: white;
            padding: $spacing;
            border-radius: 5px;
            width: calc(100% - #{$spacing * 6});
            margin: 0 auto;
            display: flex;
            align-items: center;

            :global(.isvg) {
                margin-right: $spacing;

                svg {
                    width: 16px;
                    height: 16px;
                }
            }

            span {
                color: getColor('mid-grey');
                font-weight: 500;
            }
        }

        &--disabled {
            #{$draftForm}-list__single-info__bottom-task {
                cursor: not-allowed;
            }

            #{$draftForm}-list__single-info__bottom-description {
                cursor: not-allowed;
            }

            #{$draftForm}-list__single-info__top-work-type {
                cursor: not-allowed;
            }

            #{$draftForm}-list__single__actions-time {
                cursor: not-allowed;
            }
        }

        &-options {
            display: flex;

            &-total {
                padding: 0 $spacing * 3.4;

                h5 {
                    margin-bottom: 0;
                    color: getColor('mid-grey');

                    span {
                        margin-left: $spacing * 2;
                    }
                }
            }

            &-submit {
                width: 120px;
                border-left: 1px solid getColor('silver-grey');
                padding: 0 $spacing * 3.4;
            }

            button {
                padding: 0;
                height: auto;
            }
        }

        &-form {
            margin-bottom: 0;
            animation: fadein 1s ease-in-out;

            &--warning {
                padding-top: 20px;
                background-color: rgba(getColor(tomato), 0.2);
            }

            &--active {
                border: 1px solid rgba(getColor(red), 0.5) !important;
            }

            &-timer {
                word-break: keep-all;
                white-space: nowrap;
                font: $font-2;
                font-weight: 700;
                color: getColor('mid-grey');
                line-height: 1;
                margin-bottom: 0;
                margin-left: $spacing * 2;
                margin-right: $spacing * 1.5;
                display: flex;
                align-items: center;
            }
        }

        &-top {
            padding: $spacing * 1.5 $spacing * 3;
            background-color: getColor('light-grey');
            display: flex;
            justify-content: space-between;

            h5 {
                margin-bottom: 0;
                color: getColor('mid-grey');
            }
        }

        &-list {
            position: relative;
            min-height: 80px + $spacing * 2;

            :global {
                .notification {
                    position: relative;
                    top: $spacing;
                }
            }

            & > form {
                border-bottom: 2px solid getColor('light-grey');

                &:last-child {
                    border-bottom: 0;
                }
            }

            &__single {
                display: flex;
                padding: $spacing * 2.5 $spacing * 3;
                max-width: 100%;
                width: 100%;

                &-info {
                    flex-basis: calc(100% - 420px);
                    max-width: calc(100% - 420px);

                    &--submitted {
                        flex-basis: calc(100% - 300px);
                        max-width: calc(100% - 300px);
                    }

                    &__top {
                        display: flex;
                        margin-bottom: $spacing * 1.5;

                        &-project {
                            align-self: center;
                            margin-bottom: 0;
                            color: getColor('mid-grey');

                            &--disabled {
                                color: getColor('silver-grey');

                                :global(.isvg) {
                                    width: 13px;
                                    margin-right: $spacing;
                                }
                            }
                        }

                        :global(.react-select__menu) {
                            min-width: 200px;
                        }

                        :global(.react-select__single-value) {
                            font: $font-list;
                            color: getColor('silver-grey');
                        }

                        :global(.react-select__dropdown-indicator svg) {
                            height: 28px;
                        }

                        &-work-type {
                            width: fit-content;
                            min-width: 50px;
                            margin-left: $spacing * 4;
                            max-width: 30%;

                            &:last-child {
                                margin-right: $spacing * 3;
                            }

                            :global(.tooltip) {
                                width: 100%;
                            }

                            button {
                                font: $font-list;
                                font-weight: 500;
                                color: getColor('silver-grey');
                                padding: 0;
                                height: unset;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }
                        }

                        &-stage {
                            width: fit-content;
                            min-width: 50px;
                            margin-left: $spacing * 4;
                            max-width: 30%;

                            &:last-child {
                                margin-right: $spacing * 3;
                            }

                            :global(.tooltip) {
                                width: 100%;
                            }

                            button {
                                font: $font-list;
                                font-weight: 500;
                                color: getColor('silver-grey');
                                padding: 0;
                                height: unset;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;

                                &:hover {
                                    color: getColor('silver-grey');
                                    cursor: unset !important;
                                }
                            }
                        }
                    }

                    &__bottom {
                        display: block;
                        width: 100%;

                        &-task {
                            max-width: 35%;
                            margin-right: $spacing * 2;
                            display: inline-block;
                            float: left;

                            button {
                                padding: 0;
                                height: auto;
                                display: flex;
                                min-width: 70px;

                                :global(.isvg) {
                                    margin-right: $spacing;
                                    width: $spacing * 1.5;
                                    min-width: $spacing * 1.5;
                                }

                                span:not(:global(.isvg)) {
                                    font: $font-list;
                                    color: getColor('mid-grey');
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                }
                            }
                        }

                        &-description {
                            overflow: hidden;
                            display: inline-block;
                            float: left;
                            max-width: 35%;

                            button {
                                padding: 0;
                                height: auto;
                                display: flex;
                                min-width: 100px;

                                :global(.isvg) {
                                    margin-right: $spacing;
                                    width: $spacing * 1.5;
                                    min-width: $spacing * 1.5;
                                }

                                span:not(:global(.isvg)) {
                                    font: $font-list;
                                    color: getColor('mid-grey');
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                }
                            }
                        }

                        &-remaining {
                            margin-right: $spacing * 3;
                            display: flex;
                            align-items: center;
                            font: $font-list;
                            color: getColor('silver-grey');
                            font-weight: 100;
                            width: fit-content;
                            float: right;

                            :global(.svg) {
                                margin-right: $spacing / 1.5;
                                fill: getColor('silver-grey');

                                svg {
                                    fill: getColor('silver-grey');
                                }
                            }

                            &--orange {
                                color: getColor(orange);

                                :global(.svg) {
                                    svg {
                                        fill: getColor(orange);
                                    }
                                }
                            }

                            &--red {
                                color: getColor(red);

                                :global(.svg) {
                                    svg {
                                        fill: getColor(red);
                                    }
                                }
                            }
                        }
                    }
                }

                &__actions {
                    display: flex;
                    align-items: center;
                    flex-basis: 500px;
                    max-width: 500px;
                    justify-content: flex-end;

                    &--submitted {
                        min-width: 300px;
                        flex-basis: 300px;
                        max-width: 300px;
                    }

                    &-billable {
                        margin-right: $spacing * 2;
                        position: relative;
                        top: 3px;

                        :global(.isvg) {
                            width: 20px;
                        }

                        &-button {
                            border: none;
                            background: none;
                            cursor: pointer;

                            :global(.isvg svg) {
                                fill: getColor(sky) !important;
                            }

                            &--disabled {
                                :global(.isvg svg) {
                                    fill: getColor(silver-grey) !important;
                                }
                            }
                        }
                    }

                    &-options {
                        border-left: 1px solid getColor('silver-grey');
                        padding: 0 $spacing * 1.5;
                        display: flex;
                        width: 120px;

                        svg {
                            fill: getColor('silver-grey') !important;
                        }
                    }

                    &-timer {
                        display: flex;
                    }

                    &-time {
                        display: flex;
                        align-items: center;
                        border-left: 1px solid getColor('silver-grey');
                        padding: 0 $spacing * 1.5;

                        &-pause {
                            svg {
                                fill: getColor(red) !important;
                            }
                        }

                        &-button {
                            font: $font-2;
                            font-weight: 500;
                            color: getColor('mid-grey');
                            margin-right: 0;
                            line-height: 1;

                            &--error {
                                color: getColor(tomato);
                            }
                        }

                        input {
                            padding-right: $spacing;
                            width: 90px;
                            margin-left: $spacing * 2;
                        }
                    }

                    &-date {
                        #{$root}__dropdown {
                            width: auto;
                            right: 15%;

                            @include breakpoint(tablet) {
                                width: 350px;
                            }

                            :global(form.form) {
                                margin-bottom: 0;
                            }

                            :global(.input-switch) {
                                margin-bottom: $spacing;
                            }
                        }

                        &-button {
                            padding-left: 0;
                            padding-right: 0;
                            margin-right: $spacing;

                            :global(.isvg) {
                                width: 18px;
                            }

                            &--today {
                                margin-right: 0 !important;

                                :global(.isvg) {
                                    margin-right: 0;
                                }
                            }
                        }

                        &-helper-text {
                            font-style: italic;
                            font-size: 12px;
                            color: getColor(mid-grey);
                            margin-bottom: $spacing * 2 !important;
                        }

                        &-picker {
                            :global(input) {
                                padding: 0;
                                margin-left: 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}