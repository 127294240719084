@import 'erpcore/assets/scss/main';

.page-notifications {
    width: 100%;
    display: block;

    .notification {
        margin-top: ($spacing * 4);
    }
}
