@import 'erpcore/assets/scss/main';

.generate-password {
    $this: &;
    width: 100%;
    margin: 0 0 ($spacing * 2);

    &:last-child {
        margin-bottom: 0;
    }

    &__password {
        width: 100%;
        display: flex;

        @include breakpoint($to: mobile) {
            flex-wrap: wrap;
        }
    }

    &__button {
        @include breakpoint($to: small) {
            flex: 0 0 100%;
            width: 100%;
            margin: 0 0 ($spacing * 2);
        }
    }
}
