@function getBreakpoint($breakpointKey) {
    @return map-get($breakpoints, $breakpointKey);
}

@mixin breakpoint($to: null, $from: null, $and: null) {
    @if $from != null and $to != null {
        @if $and != null {
            @media (min-width: getBreakpoint($from)) and (max-width: getBreakpoint($to)) and ($and) {
                @content;
            }
        } @else {
            @media (min-width: getBreakpoint($from)) and (max-width: getBreakpoint($to)) {
                @content;
            }
        }
    } @else if $to != null {
        @if $and != null {
            @media (max-width: getBreakpoint($to)) and ($and) {
                @content;
            }
        } @else {
            @media (max-width: getBreakpoint($to)) {
                @content;
            }
        }
    } @else if $from != null {
        @if $and != null {
            @media (min-width: getBreakpoint($from) + 1) and ($and) {
                @content;
            }
        } @else {
            @media (min-width: getBreakpoint($from) + 1) {
                @content;
            }
        }
    }
}
